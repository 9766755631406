import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import about from "../../assets/images/real/about.jpg";

import Navbar from "../../component/Navbar/navbar";
import CookieModal from "../../component/cookieModal";
import Footer from "../../component/Footer/footer";
import background from "../../assets/images/capital.jpg";

import "../../../node_modules/react-modal-video/css/modal-video.css";

import aboutImage from "../../assets/images/podcast/ab.jpg";
import logoIcon from "../../assets/images/logo-icon-30.png";

import * as Icon from "react-feather";
import {
  FaArrowRight,
  BiBookmark,
  BiArrowToLeft,
  BiArrowToRight,
  TbPlayerPause,
} from "../../assets/icons/icons";
import GetInTuch from "../../component/getInTuch";

import { realEstate, workData } from "../../data/dataFour";
import { feedback } from "../../data/data";
import AppContext from "../../context/AppContext";
import Estate from "../../component/Estate";

export default function IndexRealEstate() {
  let [isOpen, setOpen] = useState(false);
  let settings = {
    container: ".tiny-three-item",
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
      992: {
        items: 3,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  };

  let project = [
    { value: "1", label: "Houses" },
    { value: "2", label: "Apartment" },
    { value: "3", label: "Offices" },
    { value: "4", label: "Townhome" },
  ];
  let minPrice = [
    { value: "1", label: "500" },
    { value: "2", label: "1000" },
    { value: "3", label: "2000" },
    { value: "4", label: "3000" },
    { value: "5", label: "4000" },
    { value: "5", label: "5000" },
    { value: "5", label: "6000" },
    { value: "5", label: "7000" },
    { value: "5", label: "8000" },
    { value: "5", label: "9000" },
  ];
  let maxPrice = [
    { value: "1", label: "500" },
    { value: "2", label: "1000" },
    { value: "3", label: "2000" },
    { value: "4", label: "3000" },
    { value: "5", label: "4000" },
    { value: "5", label: "5000" },
    { value: "5", label: "6000" },
    { value: "5", label: "7000" },
    { value: "5", label: "8000" },
    { value: "5", label: "9000" },
  ];
  const { regions, flats } = useContext(AppContext);

  return (
    <>
      <Navbar />
      <section className="relative mt-20 bg-gray-200">
        <div className="container-fluid relative md:mx-4 mx-2">
          <div className="relative pt-48 pb-60 table w-full rounded-2xl shadow-md overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div
                className="image-wrap absolute -top-[350px] -bottom-[350px] -start-[100px] -end-[100px] min-w-full w-auto min-h-full h-auto overflow-hidden m-auto"
                id="home"
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundImage: `url(${background})`,
                }}
              ></div>
            </div>
            <div className="absolute inset-0 bg-black/60"></div>
            <div className="container relative">
              <div className="grid grid-cols-1">
                <div className="flex flex-col justify-center align-middle">
                  <h1
                    className="font-bold text-white lg:leading-normal leading-normal text-center text-4xl lg:text-5xl mb-6 font-cairo"
                    style={{ fontFamily: "Cairo,sans-serif" }}
                  >
                    بيوت العرب للتسويق العقاري والسياحي
                  </h1>
                  <p className="text-white/70 w-full text-center text-xl">
                    افضل عروض الشقق المفروشه بالمهندسين والشيخ زايد ومدينه نصر
                    وعلي النيل
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="p-8 rounded-lg max-w-5xl mx-auto my-10 text-center">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">
          يتوفر لدينا شقق فندقية مفروشة بجميع أنحاء القاهرة
        </h2>
        <p className="text-gray-600 leading-relaxed mb-6">
          بأسعار مناسبة وتعد الأرخص والأفضل فى السوق للإيجار اليومي أو الشهري.
          في المهندسين، الدقي، الشيخ زايد. نضمن لكم أفضل الأسعار شقق شبابي
          وعائلات تتوفر فيها حرية المنزل وراحة الفنادق.
        </p>
      </div>

      <section className="relative md:pb-24 pb-16 bg-gray-200">
        <div className="container relative">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative -mt-32">
              <div className="grid grid-cols-1">
                {/* <div>
                                    <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700">
                                    <div className="registration-form text-dark text-start">
                                        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                    <div>
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <LuSearch className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/>
                                            <input name="name" type="text" id="job-keyword" className="form-input lg:rounded-t-sm lg:rounded-e-none lg:rounded-b-none lg:rounded-s-sm lg:outline-0 w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-0" placeholder="ابحث عن منزلك" />
                                        </div>
                                    </div>

                                    <div>                                                      
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <AiOutlineHome className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={project} placeholder=" اختر النوع"/>

                                        </div>
                                    </div>

                                    <div>                                                       
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <AiOutlineDollar className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={minPrice} placeholder="اختر أقل سعر"/>

                                        </div>
                                    </div>

                                    <div>                                                       
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <AiOutlineDollar className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={maxPrice} placeholder="اختر أعلى سعر"/>

                                        </div>
                                    </div>

                                    <div className="lg:mt-6">
                                        <input type="submit" id="search-buy" name="search" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded" value="بحث" />
                                    </div>
                                </div>
                            </div>
                                    </form>
                                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div
          className="container relative md:mt-24 mt-16 bg-gray-200"
          id="props"
        >
          {/* <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Featured Properties</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h3 className="font-bold text-black lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6 font-cairo">
              احجز شقتك حسب المكان
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              لدينا افضل شقق مفروشة في ارقي مناطق القاهرة والمهندسين والشيخ زايد
            </p>
          </div> */}

          {regions?.map((r) => {
            return (
              <div key={r.id}>
                <p className="mt-5 mb-5 text-5xl font-bold text-gray-800">
                  شقق {r.name}
                </p>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-20 ">
                  {flats
                    .filter((f) => f.c_id == r.id)
                    .map((data) => {
                      return <Estate data={data} />;
                    })}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className="container relative md:mt-24 mt-16 bg-gray-200 mb-5"
          id="service"
        >
          <div className="grid grid-cols-1 pb-8 text-center bg-gray-200">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              خدماتنا
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              مع بيوت العرب نصنع قيمة حقيقة لكل عقار تملكه أو ستملكه{" "}
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {workData.map((item, index) => {
              let Icons = item.icon;
              return (
                <div
                  key={index}
                  className="group relative lg:px-10 transition-all duration-500 ease-in-out rounded-md bg-white dark:bg-slate-900 overflow-hidden text-center py-10 px-10"
                >
                  <div className="relative overflow-hidden text-transparent -m-3">
                    <Icon.Hexagon className="size-32 fill-indigo-600/5 mx-auto"></Icon.Hexagon>
                    <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-md transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                      <Icons className="size-8" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <h5 className="text-xl font-medium">{item.title}</h5>
                    <p className="text-slate-400 mt-3">{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="about"
        >
          <div className="container relative">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center md:gap-[30px] gap-12">
              <div className="md:col-span-5">
                <div className="relative">
                  <div className="shadow-md dark:shadow-gray-800 rounded-t-full border-8 border-gray-200 dark:border-gray-900">
                    <img
                      src={aboutImage}
                      className="shadow-md rounded-t-full"
                      alt=""
                    />
                  </div>

                  {/* <div className="absolute -bottom-6 -end-2">
                    <div className="bg-white dark:bg-slate-800 w- rounded-xl shadow-md dark:shadow-gray-800 w-[300px] border-b border-gray-100 dark:border-gray-600">
                      <div className="p-6 space-y-6">
                        <div className="flex items-center space-x-3">
                          <img src={logoIcon} alt="" />
                          <h5 className="text-xl font-semibold">
                            Techwind Podcast
                          </h5>
                        </div>
                        <div className="space-y-2">
                          <div className="relative">
                            <div className="bg-gray-100 dark:bg-slate-700 rounded-full overflow-hidden">
                              <div
                                className="bg-indigo-600 w-1/2 h-1.5"
                                role="progressbar"
                                aria-label="music progress"
                              ></div>
                            </div>
                            <div className="ring-indigo-600 ring-2 absolute left-1/2 top-1/2 size-4 -mt-2 -ml-2 flex items-center justify-center bg-white rounded-full shadow">
                              <div className="w-1.5 h-1.5 bg-indigo-600 rounded-full ring-1 ring-inset ring-slate-900/5"></div>
                            </div>
                          </div>
                          <div className="flex justify-between text-sm leading-6 font-medium tabular-nums">
                            <div className="text-indigo-600">24:16</div>
                            <div className="text-slate-400">75:50</div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-50 dark:bg-slate-700 flex items-center rounded-b-xl">
                        <div className="flex-auto flex items-center justify-evenly">
                          <button type="button" aria-label="Add to favorites">
                            <BiBookmark className="text-xl text-slate-400 hover:text-indigo-600" />
                          </button>
                          <button
                            type="button"
                            className="hidden sm:block lg:hidden xl:block"
                            aria-label="Previous"
                          >
                            <BiArrowToLeft className="text-2xl text-slate-400 hover:text-indigo-600" />
                          </button>
                        </div>
                        <button
                          type="button"
                          className="bg-white dark:bg-slate-800 flex-none -my-2 mx-auto size-20 rounded-full border border-gray-100 dark:border-gray-600 shadow-md flex items-center justify-center"
                          aria-label="Pause"
                        >
                          <TbPlayerPause className="text-4xl text-slate-400 hover:text-indigo-600" />
                        </button>
                        <div className="flex-auto flex items-center justify-evenly">
                          <button
                            type="button"
                            className="hidden sm:block lg:hidden xl:block"
                            aria-label="Next"
                          >
                            <BiArrowToRight className="text-2xl text-slate-400 hover:text-indigo-600" />
                          </button>
                          <button
                            type="button"
                            className="text-slate-400 hover:text-indigo-600"
                          >
                            1x
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="md:col-span-7">
                <div className="lg:ms-4">
                  <h4 className="mb-6 md:text-4xl text-3xl lg:leading-normal leading-normal font-bold">
                    من هم <br /> بيوت العرب ؟
                  </h4>
                  <p className="text-slate-400 max-w-xl mb-2">
                    نقدم لك منصة عقارية مبتكرة تتيح لك البحث واستكشاف أفضل
                    العقارات بالمهندسين , الشيخ زايد , مدينة نصر وعلي النيل
                    بسهولة تامة، مما يوفر لك تجربة فريدة سواء كنت تبحث عن شراء
                    أو استثمار.
                  </p>
                  <p className="text-slate-400 max-w-xl">
                    تتيح منصتنا للمستخدمين الاطلاع على التفاصيل الكاملة للعقارات
                    بكل شفافية، والتحكم الكامل في اختياراتهم. معنا، ستكون الخطوة
                    التالية في رحلتك العقارية سهلة وموثوقة.
                  </p>

                  <div className="mt-4">
                    <Link
                      to="/flats"
                      className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-3"
                    >
                      تصفح الوحدات
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <GetInTuch />
      </section>

      {/* <Footer /> */}
    </>
  );
}
