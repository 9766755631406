import React from "react";
import { Link } from "react-router-dom";

import { FiPhone } from "../assets/icons/icons";
import { FaFacebook, FaWhatsapp } from "react-icons/fa6";

export default function GetInTuch({ title }) {
  return (
    <div className="container relative md:mt-24 mt-16 " id="contact">
      <div className="grid grid-cols-1 text-center">
        {title === true ? (
          <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
            Contact Us
          </h6>
        ) : (
          ""
        )}
        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
          هل لديك سؤال؟ ابق على تواصل معنا
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          نحن هنا لمساعدتك! إذا كان لديك أي استفسار أو ترغب في معرفة المزيد عن
          خدماتنا، فلا تتردد في الاتصال بنا. فريقنا جاهز للرد على جميع أسئلتك
          وتقديم الدعم الذي تحتاجه. يمكنك التواصل معنا عبر الهاتف، واتساب ، أو
          زيارة مكاتبنا في أي وقت خلال ساعات العمل. نسعد بخدمتك ونتطلع إلى سماعك
          قريبًا.
        </p>

        <div className="mt-6 flex justify-center gap-8">
          <a
            href="https://wa.me/201090156031?text=Hello%2C%20I%20am%20interested%20in%20the%20product%20"
            target="_blank"
            className="py-2 px-5 flex justify-center items-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-green-500 hover:bg-green-600 border-green-500 hover:border-green-600 text-white rounded-md mt-4"
          >
            <FaWhatsapp className="me-1 text-lg" /> واتساب
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61565112732998"
            target="_blank"
            className="py-2 px-5 flex justify-center items-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-blue-500 hover:bg-blue-600 border-blue-500 hover:border-blue-600 text-white rounded-md mt-4"
          >
            <FaFacebook /> الفيس بوك
          </a>
        </div>
      </div>
    </div>
  );
}
