import axios from "axios";
import { createContext, useEffect, useState } from "react";

const AppContext = createContext();

export const AppContextProvider = (props) => {
  const { children } = props;
  let [flats, setFlats] = useState([]);
  let [regions, setRegions] = useState([]);
  {
    /*ehabs*/
  }
  // const getRegions = async () => {

  //     const resp = await axios.get("https://api.millennium-eg.net/regions");
  //     return resp.data;

  // };
  // const getFlats = async () => {

  //     const resp = await axios.get("https://api.millennium-eg.net/flats");
  //     return resp.data;

  // };
  // useEffect(() => {
  //     getRegions().then((response) => {
  //         setRegions(response)
  //         console.log(regions)
  //     })
  // }, [])

  useEffect(() => {
    const getRegions = async () => {
      try {
        const resp = await axios.get("https://api.bayutalarab.com/regions");
        const allRegions = resp.data;
        console.log("Fetched regions:", allRegions); // Log fetched data
        setRegions(allRegions);
      } catch (err) {
        console.error("Error fetching regions:", err);
      }
    };

    getRegions();
  }, []);
  useEffect(() => {
    console.log("Updated regions:", regions);
  }, [regions]);
  useEffect(() => {
    const getFlats = async () => {
      try {
        const resp = await axios.get("https://api.bayutalarab.com/flats");
        const allFlats = resp.data;
        console.log("Fetched flats:", allFlats); // Log fetched data
        setFlats(allFlats);
      } catch (err) {
        console.error("Error fetching flats:", err);
      }
    };

    getFlats();
  }, []);
  useEffect(() => {
    console.log("Updated flats:", flats);
  }, [flats]);

  // useEffect(() => {
  //     console.log("Updated regions:", regions);
  // }, [regions]);
  // const getRegions = async () => {

  //     const resp = await axios.get("https://api.millennium-eg.net/regions");
  //     return resp.data;
  //     // console.log(allRegions)
  //     // setRegions(allRegions)
  //     // console.log(regions)

  // };

  // useEffect(() => {
  //     getRegions().then((response) => {
  //         setRegions(response)
  //     })
  //     console.log("Updated regions:", regions);
  // }, []);
  // const getFlats = async () => {
  //     try {
  //         const resp = await axios.get("https://e720-197-43-134-182.ngrok-free.app/flats");
  //         console.log(resp.data)
  //         console.log(resp.data.address)

  //         setFlats(resp.data)
  //         console.log(flats)
  //     }
  //     catch (err) {
  //         console.error("Error fetching flats:", err);
  //     }
  // }
  // useEffect(() => {
  //     getRegions();
  //     // getFlats()

  // }, []);
  // useEffect(() => {

  //     console.log("Updated regions:", regions);
  // }, [regions]);

  return (
    <AppContext.Provider value={{ regions, flats }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppContext;
