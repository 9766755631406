import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";

import IndexRealEstate from "./pages/index/indexRealEstate";
import Switch from "./component/Switch";
import { AppContextProvider } from "./context/AppContext";
import ShopGridTwo from "./pages/ecommerce/shopGridTwo";
import { FaWhatsappSquare } from "react-icons/fa";

export default function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        {/* <Switch/> */}
        <Routes>
          <Route path="/" element={<IndexRealEstate />} />
          <Route path="/flats" element={<ShopGridTwo />} />
        </Routes>
      </BrowserRouter>
      <a
        href="tel:01090156031"
        className="w-full h-14 bg-red-600 hover:bg-red-600  fixed bottom-0 flex justify-center p-2 text-white text-lg font-bold"
      >
        اتصل الان
      </a>

      <a
        className="fixed bottom-16 right-2 block z-999"
        href="https://wa.me/201090156031?text=Hello%2C%20I%20am%20interested%20in%20the%20product%20"
      >
        <FaWhatsappSquare className="fill-green-600 text-7xl" />
      </a>
    </AppContextProvider>
  );
}
