import React from "react";
import { LiaCompressArrowsAltSolid } from "react-icons/lia";
import { LuBath, LuBedDouble } from "react-icons/lu";
import { Link } from "react-router-dom";

const Estate = ({ data, showDetails }) => {
  return (
    <div
      key={data.id || Math.round(Math.random() * 100)}
      className="group rounded-md bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-800 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500 w-96"
    >
      <div className="relative">

        <img className="h-64 w-full" src={data.image_url} alt="" />

        <div className="absolute top-6 end-6">
          {/* <Link
to="#"
className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-lg text-center bg-white dark:bg-slate-900 border-0 shadow dark:shadow-gray-800 rounded-full text-red-600"
>
<i className="mdi mdi-heart"></i>
</Link> */}
        </div>

        {/* Rating and Price */}
        <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60 to-transparent text-white">
          <ul className="flex justify-between items-center">
            <li>
              {/* <span className="text-slate-300">Price</span> */}
              {/* <p className="text-lg font-medium">{data.price} ج.م</p> */}
            </li>
            <li>
              {/* <span className="text-slate-300">Rating</span> */}
              <ul className="text-lg font-medium text-amber-400 list-none flex items-center">
                <li className="inline">
                  <i className="mdi mdi-star"></i>
                </li>
                <li className="inline">
                  <i className="mdi mdi-star"></i>
                </li>
                <li className="inline">
                  <i className="mdi mdi-star"></i>
                </li>
                <li className="inline">
                  <i className="mdi mdi-star"></i>
                </li>
                <li className="inline">
                  <i className="mdi mdi-star"></i>
                </li>
                <li className="inline text-white">
                  5.0 ({Math.round(Math.random() * 100)})
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6">
        <div className="pb-6">
          <Link
            to="#"
            className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500"
          >
            {data.address}
          </Link>
        </div>
        <div className="pb-6">
          <Link
            to="#"
            className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500 text-slate-600"
          >
            {data.title}
          </Link>
        </div>

        {showDetails && (
          <>
            <details
              style={{ cursor: "pointer" }}
              className="text-lg text-slate-600 hover:text-indigo-600 font-medium ease-in-out duration-500 mb-5"
            >
              <summary className="font-medium">شاهد التفاصيل</summary>
              <div
                style={{
                  width: "100%",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                {data.desc}
              </div>
            </details>
            {JSON.parse(data?.features).length && (
              <details
                style={{ cursor: "pointer" }}
                className="text-lg text-slate-600 hover:text-indigo-600 font-medium ease-in-out duration-500 mb-5"
              >
                <summary>المميزات</summary>
                {JSON.parse(data?.features).map((f) => (
                  <p>{f}</p>
                ))}
              </details>
            )}
          </>
        )}

        <ul className="py-6 border-y border-gray-100 dark:border-gray-800 flex items-center list-none">
          {/* <li className="flex items-center me-4">
            <LiaCompressArrowsAltSolid className="text-2xl me-2 text-indigo-600" />
            <span>{data.area}</span>
          </li>

          <li className="flex items-center me-4">
            <LuBedDouble className="text-2xl me-2 text-indigo-600" />
            <span>{data.rooms}</span>
          </li> */}

          <li className="flex items-center">
            <LuBath className="text-2xl me-2 text-indigo-600" />
            <span>{data.bathrooms}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Estate;
